import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  const images = [
    {
      imageUrl: './comp-pic.jpg',
      text: 'Bulat & Poustie Chartered Professional Accountants',
    },
    {
      imageUrl: './back.jpg',
      text: 'Accounting Services, Audits, Tax Planning & More',
    },
    {
      imageUrl: './win-city.jpg',
      text: '1700 Ness Ave',
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const intervalRef = useRef(null);

  const startInterval = useCallback(() => {
    intervalRef.current = setInterval(() => {
      if (!isPaused) {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
      }
    }, 7000);
  }, [isPaused, images.length]);

  useEffect(() => {
    startInterval();

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [startInterval]);

  const resetInterval = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      if (!isPaused) {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
      }
    }, 7000);
  };

  const selectSlide = (index) => {
    setCurrentSlide(index);
    resetInterval();
  }; 

  const togglePause = () => {
    setIsPaused((prevIsPaused) => !prevIsPaused);
  };

  const scrollToAbout = () => {
    const aboutSection = document.getElementById('about-section');
    aboutSection.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToServices = () => {
    const servicesSection = document.getElementById('services-section');
    servicesSection.scrollIntoView({ behavior: 'smooth' });
  };

  const redirectToContact = () => {
    window.location.href = '/contact';
  };

  const redirectToGoogleMaps = () => {
    window.open(
      'https://www.google.com/maps/place/1700+Ness+Ave,+Winnipeg,+MB+R3J+0E1/@49.8834852,-97.2104253,17z/data=!3m1!4b1!4m6!3m5!1s0x52ea737b1528363f:0xb075b8176460d5f2!8m2!3d49.8834818!4d-97.2078504!16s%2Fg%2F11c27w2_gh?entry=ttu',
      '_blank'
    );
  };

  const renderSlides = () => {
    return images.map((slide, index) => {

      let buttons;
      if (index === 0) {
        buttons = (
          <div className="buttons">
            <button onClick={scrollToAbout}>About</button>
            <button onClick={redirectToContact}>Contact</button>
          </div>
        );
      } else if (index === 1) {
        buttons = (
          <div className="buttons">
            <button onClick={scrollToServices}>Services</button>
          </div>
        );
      } else if (index === 2) {
        buttons = (
          <div className="buttons">
            <button onClick={redirectToGoogleMaps}>Get Directions</button>
          </div>
        );
      } else {
        buttons = null;
      }

      return (
        <div
          key={index}
          className={`slide ${index === currentSlide ? 'active' : ''}`}
        >
          <img src={slide.imageUrl} alt={`Slide ${index + 1}`} className="head-photo" />
          <div className="slide-text">{slide.text}</div>
          {buttons}
        </div>
      );
    });
  };

  const renderDots = () => {
    return images.map((_, index) => (
      <div
        key={index}
        className={`dot ${index === currentSlide ? 'active' : ''}`}
        onClick={() => selectSlide(index)}
      />
    ));
  };

  return (
    <div>

      <div className="slideshow">
        {renderSlides()}
        <button className="pause-button" onClick={togglePause}>
          {isPaused ? '▷' : '| |'}
        </button>
      </div>
      <div className="dots">{renderDots()}</div>

      <div className="container">
        <section id="about-section">
          <h1>- About Us -</h1>
          <p>
            Bulat & Poustie is a Manitoba firm of Chartered Professional Accountants operating a
            full-time office in Winnipeg. We are a group of professionals providing services to
            owner-managed businesses and not-for-profit organizations all across Canada. Currently,
            our office includes Chartered Professional Accountants and support staff,
            which include CPA students, technicians, and administrative staff.
          </p>
          <p>
          Successful relationships are based on confidence, trust and understanding. At Bulat & Poustie Chartered Professional Accountants, 
          the combination of these principles is the foundation of our business.
          </p>
          <h3>Vision Statement</h3>
          <p>
            Bulat & Poustie will provide the highest quality accounting and business advisory
            services for all of our clients. We have high-quality standards and provide our clients
            with professional and high-quality services.
          </p>
        </section>

        <div className="partner-section">
          <h1>Our Partners</h1>
          <h2>Tim Cattani</h2>
          <h4>Partner</h4>
          <h5>Taxation Services</h5>
          <img src="./tc.png" alt="Tim Cattani" />
          <h2>Ira Friesen</h2>
          <h4>Partner</h4>
          <h5>Assurance Services</h5>
          <img src="./if.png" alt="Ira Friesen" />
        </div>

        <section id="services-section">
          <h1>- Services -</h1>
          <p>
            Bulat & Poustie offers a wide range of professional services to our individual and
            business clients. Our clients benefit from personalized, quality service that is beyond
            comparison.
          </p>
          <ul>
            <li>Accounting Services</li>
            <li>Audits, Reviews, & Compilations</li>
            <li>Cash Flow & Budgeting Analysis</li>
            <li>Tax Planning</li>
            <li>Consulting Services</li>
            <li>Business Valuations</li>
            <li>Purchase and Sale of a Business</li>
          </ul>
          <p>Click below to see a more extensive breakdown of our services:</p>
          <Link to="/services" className="link-button">
            Services
          </Link>
        </section>

        <div className='career-section'>
            <h1>Careers</h1>
            <p>Bulat & Poustie offers a number of exciting opportunities for CPA students and experienced CPAs. </p>
            <p>We are a growing firm and encourage growth from within.   We are always in the market for strong employees.</p>
            <p>Whether you are just beginning your career or are ready to take the next step, Bulat & Poustie provides qualified candidates a challenging, extremely rewarding experience.</p>
            <h2>Contact us for more information.</h2>
        </div>
      </div>

      <div className="footer">
        <img src="BP-logo.png" alt="Bulat & Poustie" />
        <div className='footer-links'>
            <a href="/"> Home </a>
            <a href="/services"> Services </a>
            <a href="/contact"> Contact </a>
        </div>
        <div className='footer-content'>
          <h3>Phone: (204) 831-1700</h3>
          <h3>Fax: (204) 831-7812</h3>
          <h3>1700 Ness Avenue</h3>
          <h3>Winnipeg, MB, Canada</h3>
        </div>
      </div>
    </div>
  );
};

export default Home;
