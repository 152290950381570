import React from 'react';
import {Link} from 'react-router-dom';

const scrollToAccountingServices = () => {
    const contactSection = document.getElementById('accser-section');
    contactSection.scrollIntoView({ behavior: 'smooth' });
};

const scrollToAudit = () => {
    const contactSection = document.getElementById('audit-section');
    contactSection.scrollIntoView({ behavior: 'smooth' });
};

const scrollToCashFLow = () => {
    const contactSection = document.getElementById('cashflow-section');
    contactSection.scrollIntoView({ behavior: 'smooth' });
};

const scrollToTaxPrep = () => {
    const contactSection = document.getElementById('taxprep-section');
    contactSection.scrollIntoView({ behavior: 'smooth' });
};

const scrollToTaxPlan = () => {
    const contactSection = document.getElementById('taxplan-section');
    contactSection.scrollIntoView({ behavior: 'smooth' });
};

const scrollToCompCons = () => {
    const contactSection = document.getElementById('compcons-section');
    contactSection.scrollIntoView({ behavior: 'smooth' });
};

const scrollToConsServ = () => {
    const contactSection = document.getElementById('consserv-section');
    contactSection.scrollIntoView({ behavior: 'smooth' });
};

const scrollToAccSoft = () => {
    const contactSection = document.getElementById('accsoft-section');
    contactSection.scrollIntoView({ behavior: 'smooth' });
};

const scrollToBusVal = () => {
    const contactSection = document.getElementById('busval-section');
    contactSection.scrollIntoView({ behavior: 'smooth' });
};

const scrollToPurchase = () => {
    const contactSection = document.getElementById('purchase-section');
    contactSection.scrollIntoView({ behavior: 'smooth' });
};

const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
};

const Services = () => {
    return (
        <div>

            <section id='top-section'>
                <h2 className='sec-head'>We'd Love to Work With You</h2>
                <h3>Bulat & Poustie offers a wide range of professional services to our individual and business clients. Our clients benefit from personalized, quality service that is beyond comparison.</h3>
                <h3>As the list below is by no means comprehensive, we encourage you to contact us and inquire about a service if you do not see it listed.</h3>
            </section>

            <div className='quick-links'>
                <Link onClick={scrollToAccountingServices}>Accounting Services</Link>
                <Link onClick={scrollToAccSoft}>Accounting Software Selection & Implementation</Link>
                <Link onClick={scrollToAudit}>Audits, Reviews, & Compilations</Link>
                <Link onClick={scrollToBusVal}>Business Valuations</Link>
                <Link onClick={scrollToCashFLow}>Cash Flow & Budgeting Analysis</Link>
                <Link onClick={scrollToCompCons}>Computer Consulting</Link>
                <Link onClick={scrollToConsServ}>Consulting Services</Link>
                <Link onClick={scrollToPurchase}>Purchase and Sale of a Business</Link>
                <Link onClick={scrollToTaxPlan}>Tax Planning</Link>
                <Link onClick={scrollToTaxPrep}>Tax Planning & Preparation</Link>
            </div>

            <div className='sections'>

            <section id='accser-section'>
                <h1>- Accounting Services -</h1>
                <p>Providing financial information to our clients in a timely and accurate manner is a commitment that we feel cannot be compromised. Meaningful, well-organized financial records ensure that your business operations will run more efficiently on a daily basis. Our firm provides a full range of cost effective accounting services, including the following:</p>
                <ul>
                    <li>General ledger & financial statement preparation</li>
                    <li>Bookkeeping (Monthly/Quarterly/Annual)</li>
                    <li>Accounting system setup for new businesses</li>
                    <li>Compilation of financial statements</li>
                    <li>Reviews of financial statements</li>
                    <li>Audits and reviews</li>
                </ul>
                <Link onClick={scrollToTop}>Return to Top</Link>
            </section>

            <section id='accsoft-section'>
                <h1>- Accounting Software Selection & Implementation -</h1>
                <p>With the number of computerized accounting software packages available for small businesses and individuals growing every day, selecting one that is right for you has become increasingly difficult. As accounting professionals, we have knowledge of most programs, either through personal experience or industry literature. Our experienced staff can help you choose and implement the software that best fits your needs.</p>
                <Link onClick={scrollToTop}>Return to Top</Link>
            </section>

            <section id='audit-section'>
                <h1>- Audits, Reviews, & Compilations -</h1>
                <p>We view our role in the financial reporting process as an opportunity to provide constructive solutions for maximizing your company's profitability and efficiency. We provide financial reporting on all three levels of assurance:</p>
                <h4>Audit - an intensive examination with the highest level of assurance</h4> 
                <p>At Bulat & Poustie, our audit team follows Canadian Auditing Standards (CASs) and adheres to rigorous quality control policies to provide you with an audit opinion that meets the highest standard.
                    Through careful review of your financial statements and other business records, the audit team analyzes and cross-checks important information about your business. This enables us to offer you valuable advice on how you can improve your internal controls, identify any fiscal irregularities before they become an issue, and help you grow your business.
                    To find out what Bulat & Poustie can do for you, please contact Ira Friesen, CPA, CA, Partner, Assurance Services at ifriesen@bandp.ca or 204-831-1700 ext. 225.</p>
                <h4>Review - some analytical procedures conducted with limited assurance</h4> 
                <p>A review engagement provides limited assurance that the financial statements are presented fairly in accordance with applicable standards. The financial statements are assessed through enquiry, analysis and discussion. In addition to a review engagement report, we can provide you with comparative benchmark analysis. This, along with other financial indicators, will help you interpret your financial results and ease your business decision-making process.</p>
                <h4>Compilation - based upon client information provided and (primarily for internal use)</h4>
                <p>Although your financial information systems will often provide you with the detailed information you need, you may require assistance putting together a set of financial information summarizing your operations. The Bulat & Poustie team can meet your compilation needs by assembling your information into professionally prepared financial information.</p>
                <Link onClick={scrollToTop}>Return to Top</Link>
            </section>

            <section id='busval-section'>
                <h1>- Business Valuations -</h1>
                <p>A professional and objective business valuation is a necessary component of many transactions, such as purchase, sale or reorganization of a business, shareholder and partnership agreements, matrimonial settlements, or estate proceedings. Our experienced team will provide you with an objective and accurate valuation of your business assets and goodwill.</p>
                <Link onClick={scrollToTop}>Return to Top</Link>
            </section>

            <section id='cashflow-section'>
                <h1>- Cash Flow & Budgeting Analysis -</h1>
                <p>A business can be profitable and still run into trouble due to cash flow issues. We can forecast and evaluate your company's financial condition, estimate financing requirements, and track cash-flow sources and uses.</p>
                <Link onClick={scrollToTop}>Return to Top</Link>
            </section>

            <section id='compcons-section'>
                <h1>- Computer Consulting -</h1>
                <p>Whether you are contemplating the addition of new computer hardware or overhauling your network, our experienced staff can assist you with many of the difficult decisions that must be made when designing your business's computer systems.</p>
                <Link onClick={scrollToTop}>Return to Top</Link>
            </section>

            <section id='consserv-section'>
                <h1>- Consulting Services -</h1>
                <p>We gauge our worth by the personal and business successes of our clients. We can help your business identify areas negatively affecting profitability and growth and develop solutions that are practical and technically sound. In addition to acting as a sounding board for management, we provide comprehensive, flexible strategies that address the issues affecting your business. The following are just a few of the areas in which we provide consulting services:</p>
                <ul>
                    <li>Accounting software selection and implementation</li>
                    <li>Business valuations</li>
                    <li>Buying or selling a business</li>
                    <li>Business process improvement</li>
                    <li>Computer systems</li>
                    <li>Financial & retirement planning</li>
                    <li>Estate Assistance with mergers, acquisitions and dispositions of businesses</li>
                    <li>Assistance with bank financing proposals</li>
                    <li>Business start-up and expansion planning</li>
                    <li>Development of internal controls</li>
                    <li>First Nations accounting and advisory services</li>
                    <li>Management reporting</li>
                    <li>Preparation of budgets, cash flow projections and forecasts</li>
                </ul>
                <Link onClick={scrollToTop}>Return to Top</Link>
            </section>

            <section id='purchase-section'>
                <h1>- Purchase and Sale of a Business -</h1>
                <p>Preparation and planning are key to the successful purchase or sale of a business. Our team can assist you with all aspects your transaction, including valuation, cash flow analysis, tax minimization and transition planning.</p>
                <Link onClick={scrollToTop}>Return to Top</Link>
            </section>

            <section id='taxplan-section'>
                <h1>- Tax Planning -</h1>
                <p>Tax planning is an essential element of the tax preparation process. By making tax planning part of your overall business strategy, you can use our experience and access to the most current tax developments to minimize your tax liabilities. Some of our services include:</p>
                <ul>
                    <li>Corporate and tax planning and preparation</li>
                    <li>Personal tax planning and preparation</li>
                    <li>Corporate reorganization</li>
                    <li>GST and PST taxation matters</li>
                    <li>Canada Revenue Agency audit assistance and negotiations</li>
                    <li>Corporate acquisitions and dispositions</li>
                    <li>Disability tax credit applications</li>
                    <li>Scientific Research and Experimental Development (SRED) claims</li>
                </ul>
                <Link onClick={scrollToTop}>Return to Top</Link>
            </section>

            <section id='taxprep-section'>
                <h1>- Tax Preparation -</h1>
                <p>Tax planning and preparation form a winning combination for our successful individual and business clients. Our experienced staff can develop tax-minimization strategies to optimize your after-tax position.</p>
                <Link onClick={scrollToTop}>Return to Top</Link>
            </section>

            </div>

            <div className="footer">
        <img src="BP-logo.png" alt="Bulat & Poustie" />
        <div className='footer-links'>
            <a href="/"> Home </a>
            <a href="/services"> Services </a>
            <a href="/contact"> Contact </a>
        </div>
        <div className='footer-content'>
          <h3>Phone: (204) 831-1700</h3>
          <h3>Fax: (204) 831-7812</h3>
          <h3>1700 Ness Avenue</h3>
          <h3>Winnipeg, MB, Canada</h3>
        </div>
      </div>
        </div>
    );
};

export default Services;