import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [recipient, setRecipient] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the recipient is still the default "Select" option
    if (recipient === '') {
      alert('Please select a recipient');
      return;
    }

    try {
      // Send the form data to the server
      const response = await emailjs.sendForm('service_rwubpd6', 'template_3tpm7rw', e.target, 'PWRzRXzG38m_Jnu7I',{
      to_email: recipient,
    });
    console.log('Email sent:', response.text);

      // Reset the form fields
      setFirstName('');
      setLastName('');
      setEmail('');
      setSubject('');
      setMessage('');
      setRecipient('');

      alert('Email sent successfully!');
    } catch (error) {
      console.error('Email failed to send:', error);
      alert('Email failed to send. Please try again later.');
    }
  };

  const redirectToCCH = () => {
    window.open('https://bandp.cchifirm.ca/clientportal');
  };

  return (
    <div>
           <div className="contact-head">
        <h1>Contact Us</h1>
        <p>
          Please use the drop-down below to choose whom you wish to contact. We will respond to your query within a few
          business days.
        </p>
        <p>Team Members of Bulat & Poustie Chartered Professional Accountants can be reached via phone at (204) 831-1700.</p>
      </div>

      <form onSubmit={handleSubmit} className="contactform">
        <label htmlFor="first-name">First Name*: </label>
        <input
          type="text"
          name="first-name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
        />
        <br />
        <br />

        <label htmlFor="last-name">Last Name*: </label>
        <input
          type="text"
          name="last-name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          required
        />
        <br />
        <br />

        <label htmlFor="email">Email*: </label>
        <input
          type="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <br />
        <br />

        <label htmlFor="subject">Subject*: </label>
        <input
          type="text"
          name="subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          required
        />
        <br />
        <br />

        <label htmlFor="message">Message*: </label>
        <textarea
          name="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        ></textarea>
        <br />
        <br />

        <label htmlFor="recipient">Recipient*: </label>
        <select
          name="recipient"
          value={recipient}
          onChange={(e) => setRecipient(e.target.value)}
        >
          <option value="">Select</option>
          <option value="ifriesen@bandp.ca">Ira Friesen, CPA, CA - Partner - Assurance Services</option>
          <option value="tcattani@bandp.ca">Tim Cattani, CPA, CA - Partner - Taxation Services</option>
          <option value="bbeckstead@bandp.ca">Brian Beckstead, CPA, CGA - Manager - Taxation Services</option>
          <option value="kferris@bandp.ca">Karisa Ferris, CPA, CA - Manager - Assurance Services</option>
          <option value="mdumaual@bandp.ca">Mickey Dumaual - CPA - Manager - Assurance Services</option>
          <option value="ntamrakar@bandp.ca">Niksan Tamrakar - Staff Accountant</option>
          <option value="tschmidt@bandp.ca">Tyler Schmidt - Staff Accountant</option>
          <option value="kspence@bandp.ca">Kristi Spence - Office Administrator</option>
        </select>
        <br />
        <br />

        <input type="submit" value="Submit" />
      </form>

      <div className="sendfile">
        <h2>- OR -</h2>
        <h1>Send Us a File</h1>
        <p>
          CCH iFirm Portal employs the very latest tried and tested security innovation. Click on the button below to
          access B&P’s secure portal. Need a guest account? Please contact us today
          and we will set up an account for you.
        </p>
      </div>

      <div className="send-open">
        <a href="mailto:kspence@bandp.ca" className="contact-button">
          Contact Us
        </a>
        <button onClick={redirectToCCH} className="contact-button">
          Open Portal
        </button>
      </div>

      <div className="footer">
        <img src="BP-logo.png" alt="Bulat & Poustie" />
        <div className='footer-links'>
            <a href="/"> Home </a>
            <a href="/services"> Services </a>
            <a href="/contact"> Contact </a>
        </div>
        <div className='footer-content'>
          <h3>Phone: (204) 831-1700</h3>
          <h3>Fax: (204) 831-7812</h3>
          <h3>1700 Ness Avenue</h3>
          <h3>Winnipeg, MB, Canada</h3>
        </div>
      </div>
    </div>
  );
};

export default Contact;
